import React, {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react"

import { TextureLoader, SpriteMaterial, Sprite } from "three"
import {ImageData, imgs, ImgSizes, connectivity} from '../../data/elements'
import { Modal } from "../Modal"
import { XCircleFill } from "@styled-icons/bootstrap"
import tw, { styled, css } from "twin.macro"
import { createPortal } from "react-dom"
import TouchManager from "../../utils/TouchManager"
import loadable from "@loadable/component"
const ForceGraph3D = loadable(() => import("react-force-graph-3d"))

// the one that will open up the model with project description
const mainNode = ['/p/F_16.jpg','F_16']

const loader = new TextureLoader()
const touch = new TouchManager()

function encodeID(s) {
  if (s === "") return "_"
  return s.replace(/[^a-zA-Z0-9.-]/g, function (match) {
    return "_" + match[0].charCodeAt(0).toString(16) + "_"
  })
}

const Follicile = forwardRef((props, ref) => {
  const [graph, setGraph] = useState(null)
  const [selectedImg, setSeelctedImg] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false);
  const handleClick = useCallback(
    node => {
      let newName = `${node.img.split('/')[2].split('-')[0].split('.')[0]}`
      setSeelctedImg(prev => newName)
      setShowModal(prev => true)
    },
    [graph]
  )
  // Deal with delayed component creation while using loadable component
  // we are using loadable component because without it gatsby build will fail
  const setGraphRef = useCallback(node => {
    if (node != null) {
      setGraph(prev => {
        if (prev == null) return node
        else return prev
      })
    }
  }, [])
  // Assuming graph object is in place, initilize touch manager
  // take into consideration it might be set and reset couple of times
  useEffect(() => {
    let updateCamera = null;
    if (graph != null) {
      touch.cleanup()
      touch.clickHandler = handleClick
      touch.forceGraphInstance = graph
      touch.init()
      graph.d3Force('link').distance(link=>100)
      // let angle = 0;
      // console.log(graph.camera())
      // updateCamera = setInterval(() => {
      //   if(!touch.isMoving && !touch.justWandering && !touch.isButtonPressed){
      //     let camPos = graph.camera().position
      //   graph.cameraPosition({
      //     x: camPos.z * Math.sin(angle),
      //     z: camPos.z * Math.cos(angle)
      //   });
      //   angle += Math.PI / 500;
      // }
      // }, 10);
    }
    return () => {
      touch.cleanup()
      // if(updateCamera) clearInterval(updateCamera)
    }
  }, [hasLoaded])
  // allow parent to call those methods
  useImperativeHandle(
    ref,
    () => ({
      zoomToFit() {
        graph?.zoomToFit(400)
      },
    }),
    [graph]
  )
  // how should the nodes look like?
  const nodeToThree = useCallback(({img}) => {
    let newName = `${img.split('.')[0]}-0.5x.jpg`
    let isMain = img == mainNode[0] 
    const imgTexture = loader.load(newName)
    const material = new SpriteMaterial({ map: imgTexture })
    const sprite = new Sprite(material)
    const myRatio = ImgSizes[img]
    if(isMain) sprite.scale.set(150, 150*myRatio)
    else {sprite.scale.set(50, 50*myRatio)}
    return sprite
  }, [])

  return (
    <>
      {/* <Suspense> */}
        <ForceGraph3D
          ref={setGraphRef}
          backgroundColor={"rgba(0,0,0,0)"}
          graphData={connectivity}
          nodeThreeObject={nodeToThree}
          onEngineTick={() => setHasLoaded(true)}
          showNavInfo={false}
          width={props.width}
          height={props.height}
          // cooldownTicks={400}
        />
        {showModal && selectedImg != mainNode[1] ? 
        (
          <Modal show={true} className={"selected-graph-node"}>
            <div
              tw="w-full h-full position[fixed] inset-0 flex flex-col justify-center align-content[center]"
              css={css`background-color: rgba(0,0,0,0.5)`}
            >
              <div tw="p-5 w-full h-full flex flex-col justify-center">
                <div tw="flex align-self[flex-end] z-50">
                  <button
                    onClick={e => {
                      setShowModal(current => false)
                      ref.current.zoomToFit()
                    }}
                    tw="w-10 h-10 text-white"
                    type="button"
                    className="close"
                  >
                    <XCircleFill tw="w-full h-full" />
                  </button>
                </div>
                <div tw={"my-4 min-h-0 max-h-screen flex flex-col justify-center align-items[center]"}>
                  <div tw={"p-4 bg-white min-h-0 flex flex-col flex-1 overflow-y-auto m-auto"}>
                  <div tw="align-self[center]  flex justify-center align-items[center]">
                <img tw={"object-cover min-w-0 flex-1  max-height[55vh] md:max-height[80vh] "} src={`/p/${selectedImg}.jpg`} alt={ImageData[selectedImg].hashtag} />
                  </div>
                <div tw={"mt-4 align-self[center] flex[0 0 auto] py-2 px-4 flex flex-col justify-center align-content[center] text-gray-900"}>
                  <p  tw={"text-align[center]"}>
                  {ImageData[selectedImg].hashtag} - 
                  <a tw={"underline text-blue-600 can-hover:hover:text-blue-800 visited:text-purple-600 cursor-pointer"} onClick={()=>{setSeelctedImg((p)=>mainNode[1]); return false}}> Read more...</a>
                  </p>
                  <p tw={"text-align[center]"}>
                  <span>&#169;</span> {ImageData[selectedImg].credits}
                  </p>
                  </div>
                </div>
                </div>

              </div>
            </div>
          </Modal>
        )
      :(null)}
      {showModal && selectedImg == mainNode[1] ?
      (
        <Modal show={true} className={"selected-graph-node"}>
          <div
              tw="w-full h-full position[fixed] inset-0 flex flex-col justify-center align-content[center]"
              css={css`background-color: rgba(0,0,0,0.5)`}
            >
              <div tw="p-5 w-full h-full flex flex-col justify-center">
              <div tw="flex align-self[flex-end] z-50">
                  <button
                    onClick={e => {
                      setShowModal(current => false)
                      ref.current.zoomToFit()
                    }}
                    tw="w-10 h-10 text-white"
                    type="button"
                    className="close"
                  >
                    <XCircleFill tw="w-full h-full" />
                  </button>
                </div>

                <div tw={"my-4 min-h-0 max-h-screen flex flex-col justify-center align-items[center]"}>
                <div tw={"max-w-2xl bg-white pb-6 min-h-0 flex flex-col flex-1 overflow-y-auto m-auto"}>
                <img tw="object-cover w-full" src={`/p/${selectedImg}.jpg`} alt={ImageData[selectedImg].hashtag} />
                <div tw="p-6 text-sm font-normal">
<p>Human hair is a complex matrix of different records of toxicity contained within the human body as a consequence of one’s living environment.</p><br />
<p>Follicle is an event and a pavilion that enables Bangkok’s inhabitants to take part in a collective effort to map toxicity in the city through people.</p><br />
<p>The event invites guests to enter the space and voluntarily participate in the hair analysis protocol, which involves cutting a small unnoticeable amount of hair and anonymously filling in a form related to your lifestyle and parts of the city.</p><br />
<p>With this information and enough participants, the results can inform citizens of new information regarding pollution in the city which is accessible online and visualized as a Toxic-Cartography.</p>
<br />
<br />
<h2 tw={"font-black"}>Credits:</h2>
<p> <span tw={"font-bold"}>A project by.</span> <br/> Pareid Architects (Deborah Lopez and Hadin Charbel) </p>
<br />
<p> <span tw={"font-bold"}>Collaborators.</span> <br/> Konlawat Meklalit, Noppa-on Plidtookpai, Pitisuda Sukumalchantra, Phatsorn Mutanone.</p>
<br />
<p> <span tw={"font-bold"}>Support.</span> <br/> Matter of Trust, Embajada de España en Bangkok, Bangkok Design Week 2019 and TCDC </p>
<br />
<p> <span tw={"font-bold"}>Image Credits.</span> <br/> Pareid &  Visut Innadda </p>
<br />
<p> <span tw={"font-bold"}>Hair analysis.</span> <br/> Mery Malandrino, Alberto Salomone, Marco Vincenti (Department of Chemistry - University of Turin, Italy) </p>
<br />
<p> <span tw={"font-bold"}>Website design.</span> <br/> Pareid Architects (Déborah Lopez and Hadin Charbel) </p>
<br />
<p> <span tw={"font-bold"}>Website development & Data Visualization.</span> <br/> Sherif Tarabishy </p>
<br />
<p> <span tw={"font-bold"}>Sound design.</span> <br/> Donnie Brosh </p>
<br />
                </div>
                </div>
                </div>
              </div>
            </div>
        </Modal>
      )
      :(null)
      }
    </>
  )
})

export default Follicile
