import { Vector2, Raycaster } from "three"
// based on post here https://github.com/vasturiano/react-force-graph/issues/80
class TouchManager {
  constructor() {
    this.initialized = false
    this.refreshCounter = 0
    this.mouseDownPos = []
    this.mouseUpPos = []
    this.v1 = new Vector2()
    this.v2 = new Vector2()
    this.isMoving = false
    this.justWandering = false;
    this.pointerCount = 0
    this.isButtonPressed = false
    this.forceGraphInstance = null
    this.clickHandler = () => {}
    this.timeOut = null
    this.refreshNodesTimeout = null
    this.getCanvas = () => {
      const wrapper = document.getElementsByClassName("scene-container")
      const canvasCollection =
        wrapper &&
        wrapper.length > 0 &&
        wrapper[0].getElementsByTagName("canvas")
      this.canvas = canvasCollection && canvasCollection[0]
    }
    this.handleClick = event => {
      let xPosition = 0
      let yPosition = 0
      if (event instanceof MouseEvent) {
        xPosition = event.layerX
        yPosition = event.layerY
      } else if (event instanceof TouchEvent) {
        xPosition = event.changedTouches[0].clientX
        yPosition = event.changedTouches[0].clientY
      }
      var mouse = new Vector2()
      var raycaster = new Raycaster()
      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components
      mouse.x = (xPosition / window.innerWidth) * 2 - 1
      mouse.y = -(yPosition / window.innerHeight) * 2 + 1
      if (this.forceGraphInstance.camera && this.forceGraphInstance.scene) {
        raycaster.setFromCamera(
          new Vector2(mouse.x, mouse.y),
          this.forceGraphInstance.camera()
        )
        const intersects = raycaster
          .intersectObjects(this.forceGraphInstance.scene().children, true)
          .filter(o => ["node"].indexOf(o.object.__graphObjType) !== -1) // Check only node objects
        const topObject = intersects.length ? intersects[0].object : null
        const topNode = topObject && topObject.__data && topObject.__data
        if (topNode) {
          this.clickHandler(topNode)
        }
      }
    }
    // after initialization keep moving around the graph for a period of time.
    this.refreshCallback = ()=>{
      if(!this.isMoving || !this.isButtonPressed){
        this.refreshCounter++
      this.forceGraphInstance.d3ReheatSimulation();
      }
      if(this.refreshCounter < 500){
        this.refreshNodesTimeout = setTimeout(this.refreshCallback, 50);
      }
    }
    this.timeout=()=>{
      this.refreshCallback();
    }
    this.mouseDownHandler = e => {
      this.mouseDownPos = [e.clientX, e.clientY]
      if (e.pointerType === "touch") this.pointerCount++
      this.isMoving = false
      this.isButtonPressed = true
    }
    this.mouseuphandler = e => {
      if (e.pointerType === "touch") this.pointerCount--
      this.isButtonPressed = false
      if (this.mouseDownPos.length > 0) {
        this.v1.fromArray(this.mouseDownPos)
        this.v2.fromArray([e.clientX, e.clientY])
        let dist = this.v1.distanceTo(this.v2)
        if (dist < 20 && this.pointerCount < 1) {
          this.handleClick(e)
        }
        this.mouseDownPos = []
      }
      this.isMoving = false
    }
    this.touchmoveHandler = e => {
      if (this.isButtonPressed || e.pointerType === "touch") {
        this.isMoving = true
        this.justWandering = false;
      }
      else{
        this.justWandering = true;
        this.isMoving = false;
      }
    }
  }

  listenToTouch() {
    var that = this
    this.canvas.addEventListener("pointerdown", that.mouseDownHandler, false)
    this.canvas.addEventListener("pointerup", that.mouseuphandler, false)
  }
  listenToMove() {
    var that = this
    this.canvas.addEventListener("pointermove", that.touchmoveHandler, false)
  }
  cleanup() {
    var that = this
    if (that.initialized) {
      this.canvas.removeEventListener(
        "pointerdown",
        that.mouseDownHandler,
        false
      )
      this.canvas.removeEventListener("pointerup", that.mouseuphandler, false)
      this.canvas.removeEventListener(
        "pointermove",
        that.touchmoveHandler,
        false
      )
      clearTimeout(that.timeOut)
      clearTimeout(that.refreshNodesTimeout)
    }
  }

  init() {
    this.getCanvas()
    if (!this.canvas) {
      this.timeOut = setTimeout(() => this.init(), 1000)
      return
    }
    var that = this
    this.listenToTouch()
    this.listenToMove()
    this.timeout();
    this.initialized = true
  }
}
export default TouchManager
