import React, { Suspense, useEffect, useState, useRef } from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components/macro"
import Layout from "../components/BasicLayout"
import SEO from "../components/seo"
import Navigation from "../components/Navigation"
import { Helmet } from "react-helmet"
import FollicileContent from "../components/follicile/Follicile"
import { Html, Loader } from "@react-three/drei"
import { ControlCamera } from "@styled-icons/material-rounded"
import useMeasure, { Options as ResizeOptions } from "react-use-measure"
import {cssNavBaseText, cssNavButton} from '../utils/cssVars'
const Follicile = props => {
  const childRef = useRef()
  const [ref, size] = useMeasure({
    scroll: true,
    debounce: { scroll: 50, resize: true },
  })

  return (
    <Layout>
      <SEO title="Follicile" />
      <Helmet>
        <style type="text/css">{`
        body {
          overflow: hidden;
        }
        html {
          overflow: hidden;
        }
    `}</style>
      </Helmet>
      <Navigation location={props.location}>
        <button tw="mx-2 inline-flex items-center justify-center p-2" css={[cssNavBaseText, cssNavButton]}
            onClick={e => (e.stopPropagation(), childRef.current.zoomToFit())}
            >
          <ControlCamera
            tw="block h-6 w-6"
          />
        </button>
      </Navigation>
      <div
        ref={ref}
        tw="h-full w-full z-10"
        css={css`
          position: absolute;
        `}
      >
        <FollicileContent
          ref={childRef}
          width={size.width}
          height={size.height}
        />
      </div>
    </Layout>
  )
}
export default Follicile
